<template>
  <div class="favorites">
    <div class="header">
      <div class="header-title">
        <p>我的收藏</p>
        <div class="sign" @click="handleSignVisible">
          <p>{{ searchTitle }}</p>
          <van-icon name="arrow-down"/>
        </div>
      </div>

      <van-tabs
          v-model="activeType"
          color="#415FD5"
          title-active-color="#415FD5"
          title-inactive-color="#9498AA"
          @change="handleTypeChange"
      >
        <van-tab
            :title="item.title"
            :name="item.value"
            v-for="(item, index) in TABS_TYPE"
            :key="item.value"
        />
      </van-tabs>
    </div>

    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
        >
          <van-cell class="card-item" v-for="(item, index) in list" :key="item.questionID" @click="handleGoPath(item)">
            <div class="tags" v-if="item.labels">
              <span v-for="(item, tagIndex) in item.labels.split(',')" :key="tagIndex">{{ item }}</span>
            </div>
            <div class="tags" v-if="item.label">
              <span v-for="(item, tagIndex) in item.label.split(',')" :key="tagIndex" v-if="item">{{ item }}</span>
            </div>
            <div class="stem" v-html="renderStem(item.exam)"/>
            <div class="footer">
              <p>{{ item.title }}</p>
              <div class="del" @click="deleteStem(item)">
                <van-icon color="#707693" size="16px" name="delete-o"/>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>

    <van-popup v-model="typeVisible" round position="bottom">
      <van-picker
          title="筛选标签"
          show-toolbar
          :columns="columns"
          @cancel="typeVisible = false"
          @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script type="application/javascript">
import katex from 'katex';
import { Dialog } from 'vant';
import shareMixin from '@/mixins/shareMixin';
import { PAPER_TYPE } from "@/consts";

const TABS_TYPE_ENUM = {
  GAO_SU: 'gaoshushang',
  XIAN_DAI: 'xiandai_standard',
  GAI_LV_LUN: 'gailv',
  ZHEN_TI: 'zhenti'
}

const TABS_TYPE = [
  {title: '高等数学', value: TABS_TYPE_ENUM.GAO_SU},
  {title: '线性代数', value: TABS_TYPE_ENUM.XIAN_DAI},
  {title: '概率论', value: TABS_TYPE_ENUM.GAI_LV_LUN},
  {title: '历年真题', value: TABS_TYPE_ENUM.ZHEN_TI}
]

const columns = [
  '最近收藏',
  '概念定义',
  '计算能力',
  '性质运算',
  '题型新颖',
  '综合性高',
  '特殊技巧',
  '自定义标签'
];

export default {
  mixins: [shareMixin],
  data () {
    return {
      columns,
      TABS_TYPE,
      TABS_TYPE_ENUM,
      searchParams: {
        type: '最近收藏'
      },
      typeVisible: false,
      activeType: TABS_TYPE_ENUM.GAO_SU,
      loading: false, // 是否在加载中
      finished: true, // 是否加载完成
      refreshing: false, // 下拉刷新中
      list: [],
    }
  },
  computed: {
    searchTitle () {
      const {type} = this.searchParams;
      return type
    }
  },
  methods: {
    onConfirm (value) {
      this.searchParams.type = value
      this.typeVisible = false
      this.query();
    },
    // 标签选择栏显示
    handleSignVisible () {
      this.typeVisible = true
    },
    // tab类型切换
    handleTypeChange (type) {
      this.activeType = type
      this.query()
    },
    // 上拉加载
    onRefresh () {
      this.query()
    },
    // 渲染题干
    renderStem (item) {
      const dom = document.createElement('div');
      item.forEach(i => {
        const spanDom = document.createElement('span');
        if (i[0] === 'str') {
          spanDom.className = 'item'
          spanDom.innerHTML = i[1]
        } else {
          spanDom.className = 'tex_item'
          spanDom.innerHTML = this.$kx.renderToString(i[1], {
            throwOnError: false
          });
        }

        dom.appendChild(spanDom)
      })
      return dom.innerHTML
    },
    // 删除题干
    deleteStem (row) {
      event.stopPropagation();
      Dialog.confirm({
        title: '提示',
        message: '是否确认移除该收藏项?',
      }).then(async () => {
        await this.$api.delAdvanceCollection({
            param: {
              // book: 'gaoshushang',
              book: this.activeType,
              zhuanti: row.zhuanti,
              exam_idx: row.exam_idx
            },
            method: 'post'
        })
        // if (this.activeType !== TABS_TYPE_ENUM.GAO_SU) {
        //   await this.$api.cancelCollection({
        //     param: {questionID: row.questionID}
        //   });
        // } else {
        //   await this.$api.delAdvanceCollection({
        //     param: {
        //       book: 'gaoshushang',
        //       zhuanti: row.zhuanti,
        //       exam_idx: row.exam_idx
        //     },
        //     method: 'post'
        //   })
        // }

        this.query()
      }).catch(() => {
      });
    },

    // 查询真题收藏目录
    async queryRealCollection () {
      try {
        const ret = await this.$api.queryRealCollection({
          param: {
            labels: this.searchParams.type
          }
        });
        this.list = ret.this_label
      } catch (err) {
        console.error(err);
      } finally {
        this.refreshing = false
        this.finished = true
        this.loading = false
      }
    },
    // 查询高数收藏项
    async queryCollection () {
      try {
        const ret = await this.$api.queryAdvanceCollection({
          param: {
            label: this.searchParams.type,
            book: this.activeType
          },
          method: 'post'
        });
        this.list = ret.this_label
      } catch (err) {
        console.error(err);
      } finally {
        this.refreshing = false
        this.finished = true
        this.loading = false
      }
    },
    query () {
      const QUERY_MAP = {
        [ TABS_TYPE_ENUM.GAO_SU ]: this.queryCollection,
        [ TABS_TYPE_ENUM.ZHEN_TI ]: this.queryRealCollection,
        [ TABS_TYPE_ENUM.GAI_LV_LUN ]: this.queryCollection,
        [ TABS_TYPE_ENUM.XIAN_DAI ]: this.queryCollection
      }
      this.loading = true
      this.refreshing = true
      this.finished = true
      QUERY_MAP[ this.activeType ]()
    },
    handleGoPath (item) {
      // 高数类型单独跳转
      if (this.activeType !== TABS_TYPE_ENUM.ZHEN_TI) {
        const [chapter, zhuanti] = item.title.split('-')
        const param = {
          zhuanti,
          book: this.activeType,
          exam_idx: item.exam_idx || 0,
          chapter,
        }
        this.$api.user_info_write({ param,method:'post' }, () => {
          this.$router.push({path:'/topic', query:{name: chapter, book: this.activeType }});
        });
      } else {
        this.$router.push({
          path: '/question',
          query: {
            chapterIndex: 0,
            sectionIndex: 0,
            questionIdx: 0,
            questionID: item.questionID,
            type: PAPER_TYPE.COLLECTION,
          }
        })
      }
    },

    async queryCollectBookTab () {
      try {
        const ret = await this.$api.queryCollectBookTab({})
        this.activeType = ret.book
        this.query()
      } catch (err) {
        console.error(err)
      }
    }
  },
  mounted () {
    this.queryCollectBookTab()
    this.initShare()
  }
}
</script>

<style lang="less" scoped>
.favorites {
  background: #F7F8FB;
  max-height: calc(100vh - 60px);

  .header {
    border-bottom: 1px solid #E6E8EF;
    background: white;

    &-title {
      padding: 16px 16px 0;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;

      > p {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #2A3155;
      }

      .sign {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #415FD5;

        > p {
          margin-right: 8px;
        }
      }
    }
  }

  .content {
    .card-item {
      width: 351px;
      margin: 12px;
      padding: 16px 12px;
      background: #FFFFFF;
      box-shadow: 0px 1px 8px 0px rgba(42, 49, 85, 0.1);
      border-radius: 12px;

      .tags {
        margin-bottom: 12px;

        > span {
          background: #7792FE;
          border-radius: 2px;
          padding: 4px 8px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #FFFFFF;
          margin-right: 8px;
        }

        > span:last-child {
          margin-right: 0px;
        }
      }

      .stem {
        /deep/ .katex {
          font-size: 16px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #2A3155;
          line-height: 28px;

          .katex-html {
            width: 327px;
          }
        }
      }

      .footer {
        padding-top: 16px;
        border-top: 1px solid #E6E8EF;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        .del {
          width: 32px;
          height: 32px;
          display: flex;
          flex-flow: row;
          justify-content: center;
          align-items: center;
        }

        > p {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #9498AA;
        }
      }
    }
  }
}
</style>
