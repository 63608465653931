export const MATH_TYPE_ENUM = {
  MATH_ONE: 's1', // 数一
  MATH_TWO: 's2', // 数二
  MATH_THREE: 's3', // 数三
  MATH_FOUR: 's4', // 数一补充
  MATH_FIVE: 's5', // 数二补充
  MATH_SIX: 's6', // 数三补充
  MATH_ALL: 's7' // 合集
}

export const REAL_QUESTION_BUY_URL = {
  [MATH_TYPE_ENUM.MATH_ONE]: 'https://shop93704106.youzan.com/wscvis/knowledge/index?kdt_id=93511938&p=contentshow&alias=3f1ygwi1sdlwinb&qr=paidcontent_3f1ygwi1sdlwinb&shopAutoEnter=1',
  [MATH_TYPE_ENUM.MATH_TWO]: 'https://shop93704106.youzan.com/wscvis/course/detail/35zj2f5x38qo2kb?kdt_id=93511938&qr=paidcontent_35zj2f5x38qo2kb&shopAutoEnter=1',
  [MATH_TYPE_ENUM.MATH_THREE]: 'https://shop93704106.youzan.com/wscvis/course/detail/35yadk0cjc0j6zs?kdt_id=93511938&qr=paidcontent_35yadk0cjc0j6zs&shopAutoEnter=1',
  [MATH_TYPE_ENUM.MATH_FOUR]: 'https://shop93704106.youzan.com/wscvis/course/detail/3equi6qs4dbrmbr?kdt_id=93511938&qr=paidcontent_3equi6qs4dbrmbr&shopAutoEnter=1',
  [MATH_TYPE_ENUM.MATH_FIVE]: 'https://shop93704106.youzan.com/wscvis/course/detail/1ykdcqpc40f0ypr?kdt_id=93511938&qr=paidcontent_1ykdcqpc40f0ypr&shopAutoEnter=1',
  [MATH_TYPE_ENUM.MATH_SIX]: 'https://shop93704106.youzan.com/wscvis/course/detail/2xbuxfa92c68imj?kdt_id=93511938&qr=paidcontent_2xbuxfa92c68imj&shopAutoEnter=1'
}

// 数学类型
export const MATH_TYPE = [
  { label: '数学一', value: MATH_TYPE_ENUM.MATH_ONE },
  { label: '数学二', value: MATH_TYPE_ENUM.MATH_TWO },
  { label: '数学三', value: MATH_TYPE_ENUM.MATH_THREE },
  // { label: '合集', value: MATH_TYPE_ENUM.MATH_ALL }
]

// 重构模式
export const REFACTOR_MODE = [
  { label: '错题优先', value: '0' },
  { label: '偏高难度', value: '1' },
  { label: '常规难度', value: '2' },
  { label: '难度偏低', value: '3' }
]


// 底部操作key枚举
export const TOOLS_TYPE_ENUM = {
  DIRECTORY: 'directory',
  COLLECTION: 'collection',
  GUIDE: 'guide',
  SITE: 'site'
}

// 底部操作
export const TOOLS_TYPE = [
  {name: '目录', icon: 'newspaper-o', key: TOOLS_TYPE_ENUM.DIRECTORY, active: false},
  {name: '收藏', icon: 'star-o', key: TOOLS_TYPE_ENUM.COLLECTION, active: false},
  // {name: '导学', icon: 'apps-o', key: TOOLS_TYPE_ENUM.GUIDE, active: false},
  {name: '考点', icon: 'certificate', key: TOOLS_TYPE_ENUM.SITE, active: false}
]

// 底部操作
export const TOOLS_TYPE_S = [
  {name: '目录', icon: 'newspaper-o', key: TOOLS_TYPE_ENUM.DIRECTORY, active: false},
  // {name: '收藏', icon: 'star-o', key: TOOLS_TYPE_ENUM.COLLECTION, active: false},
  // {name: '导学', icon: 'apps-o', key: TOOLS_TYPE_ENUM.GUIDE, active: false},
  // {name: '考点', icon: 'certificate', key: TOOLS_TYPE_ENUM.SITE, active: false}
]

// 试卷类型
export const PAPER_TYPE = {
  REAL_QUESTION: 'zhenti', // 真题精炼
  REFACTOR_REAL: 'chongzu', // 真题重组
  REFACTOR_TOPIC: 'topic', // 专题重组
  COLLECTION: 'collection', //收藏
}

// 题类型
export const QUESTION_TYPE = {
  SELECT: '单选',
  FILL: '填空',
  SHORT_ANSWER: '解答',
  ASSIST_FILL: '辅助填空'
}

export const SUBMIT_TYPE = {
  TO_SUBMIT: 'TO_SUBMIT', // 待提交
  SHORT_CONFIRM: 'SHORT_CONFIRM', // 简答题确认
  SUBMITTED: 'SUBMITTED', // 填空选择题已提交
}

export const QUESTION_TAG = [
  {label: '概念定义', value: '概念定义', key: 1},
  { label: '计算能力', value: '计算能力', key: 2 },
  { label: '性质运用', value: '性质运用', key: 3 },
  { label: '题型新颖', value: '题型新颖', key: 4 },
  { label: '综合性高', value: '综合性高', key: 5 },
  { label: '特殊技巧',value: '特殊技巧', key: 6 }
]

// 提交题目目录id
export const CATALOG_ID_ENUM = {
  [PAPER_TYPE.REAL_QUESTION]: '1',
  [PAPER_TYPE.REFACTOR_TOPIC]: '2',
  [PAPER_TYPE.REFACTOR_REAL]: '3',
  [PAPER_TYPE.COLLECTION]: 4
}

export const BOOK_TYPE = {
  'gaoshu': '高等数学',
  'xiandai': '线性代数',
  'gailv': '概率统计'
}
